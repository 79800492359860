<template>
  <b-card v-if="lead.id" no-body>
    <b-card-body>
      <b-row v-if="!noHeader" class="border-bottom mb-3 pb-3">
        <b-col>
          <b-row>
            <b-col cols="12" lg="8" class="d-lg-flex align-items-center">
              <h4>
                <InlineEditor
                  :placeholder="$t('label.firstName')"
                  v-model="lead.fname"
                />&nbsp;
                <InlineEditor
                  :placeholder="$t('label.lastName')"
                  v-model="lead.lname"
                /></h4
            ></b-col>
            <b-col cols="12" lg="8" class="d-lg-flex align-items-center">
              <h4>
                <InlineEditor
                  :placeholder="$t('label.firstName')"
                  v-model="lead.clientFname"
                />&nbsp;
                <InlineEditor
                  :placeholder="$t('label.lastName')"
                  v-model="lead.clientLname"
                /></h4
            ></b-col>
          </b-row>
        </b-col>
        <b-col cols="12" lg="4" class="d-lg-flex justify-content-end">
          <b-button-group>
            <b-btn
              size="sm"
              title="Edit Details"
              variant="outline-warning"
              @click="$router.push({ name: 'LeadForm', params: { id: lead.id } })"
              v-b-tooltip.hover
              ><b-icon-pencil></b-icon-pencil>
            </b-btn>
            <b-btn
              v-if="lead.phone"
              size="sm"
              title="Place a Call"
              variant="outline-primary"
              @click="onPlaceCall"
              v-b-tooltip.hover
              ><b-icon-telephone></b-icon-telephone> {{ lead.phone | formatPhoneNumber }}
            </b-btn>
            <b-btn
              size="sm"
              title="Start an Enrollment"
              variant="success"
              @click="onEnroll"
              v-b-tooltip.hover
              >Start Enrollment
              <b-icon-arrow-right-circle-fill></b-icon-arrow-right-circle-fill>
            </b-btn>
          </b-button-group>
        </b-col>
      </b-row>

      <p class="border-bottom mb-3 pb-3">
        Franchise:
        <InlineEditor v-model="lead.franchiseCode" type="franchise" />
      </p>
      <b-row>
        <b-col cols="12" md="6" lg="3" class="border-right">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                v-model="lead.managed"
                :value="true"
                :unchecked-value="false"
                style="margin-top: -2px"
              />
              {{ $t('lead.sscManaged') }}
            </label>
          </div>
          <p>
            <big
              >{{ $t('label.status') }}:
              <InlineEditor
                v-model="lead.status"
                type="select"
                :options="leadStatusList"
                :disabled="!editableFields.status"
            /></big>
          </p>
          <p>
            {{ $t('label.dateAdded') }}: {{ $d(lead.date).format("MMM D, 'YY h:mma") }}
            <br />
            {{ $t('label.dateUpdated') }}:
            {{ $d(lead.lastUpdated).format("MMM D, 'YY h:mma") }}
          </p>
          <p>
            {{ $t('label.rep') }}:
            <InlineEditor v-model="lead.rep" type="select" :options="repList" />
          </p>
          <p></p>
        </b-col>
        <b-col cols="12" md="6" lg="3" class="border-right">
          <address>
            <InlineEditor placeholder="Address" v-model="lead.street1" />
            <InlineEditor v-model="lead.street2" placeholder="Apt # " /><br />
            <InlineEditor placeholder="City" v-model="lead.city" />,
            <InlineEditor
              v-model="lead.state"
              type="select"
              :options="statesList"
            /><br />
            <InlineEditor :placeholder="$t('label.zipCode')" v-model="lead.zipCode" />
          </address>
          <b-button
            size="sm"
            class="px-0"
            variant="link"
            :href="lead.phone ? 'tel:' + lead.phone.replace(/([^0-9\+])+/g, '') : null"
            title="Click to Call"
            >{{ $t('label.phone') }}</b-button
          >: <InlineEditor v-model="lead.phone" type="tel" /><br />
          <b-button
            size="sm"
            class="px-0"
            variant="link"
            :href="'mailto:' + lead.email"
            title="Click to Email"
            >{{ $t('label.email') }}</b-button
          >: <InlineEditor v-model="lead.email" type="email" />
        </b-col>
        <b-col cols="12" lg="6">
          <b-alert v-if="lead.message" variant="info" show>
            {{ lead.message }}
          </b-alert>
          <p>{{ $t('lead.source') }}: <InlineEditor v-model="lead.refSource" /><br /></p>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InlineEditor from '@/components/InlineEditor'
import states from '@/shared/country_states'
import { leadEditableFields } from '@/shared/lead'

export default {
  name: 'LeadDetails',
  components: { InlineEditor },
  props: {
    leadId: {
      type: [Number, String],
      default: null,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    disableFieldEdit: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      ini: true,
      originalLead: {},
      lead: {
        id: null,
        franchiseCode: null,
        date: null,
        fname: null,
        lname: null,
        clientFname: null,
        clientLname: null,
        street1: null,
        street2: null,
        city: null,
        state: null,
        country: null,
        zipCode: null,
        message: null,
        email: null,
        phone: null,
        refSource: null,
        status: null,
        managed: null,
        client: null, // ?associated client id
        rep: null,
      },
      editableFieldsDefault: leadEditableFields,
      updatedLeadNotes: [],
    }
  },
  computed: {
    ...mapGetters(['leadStatusOptions', 'users']),
    editableFields() {
      for (const k in this.editableFieldsDefault) {
        if (this.disableFieldEdit.includes(k)) {
          delete this.editableFieldsDefault[k]
        }
      }
      return { ...this.editableFieldsDefault }
    },
    statesList() {
      let res = []
      let country = this.lead.country || 'US'
      let keys = Object.keys(states[country])
      for (let k in keys) {
        let item = {
          value: keys[k],
          text: keys[k], // states[country][keys[k]]
        }
        res.push(item)
      }
      return res
    },
    repList() {
      return this.users.map((e) => ({
        value: e['@id'],
        text: `${e.profile.fname} ${e.profile.lname}`,
      }))
    },
    leadStatusList() {
      return this.leadStatusOptions.map((e) => ({
        value: e['@id'],
        text: e.status,
      }))
    },
  },
  methods: {
    ...mapActions([
      'getLead',
      'getUsers',
      'patchLead',
      'getLeadStatuses',
      'postLeadNote',
    ]),
    setLead() {
      const lead = { ...this.$store.getters.lead }
      lead.status = lead.status['@id']
      this.originalLead = lead
      this.lead = { ...this.originalLead }
    },
    onEnroll() {
      this.$router.push({ name: 'SscBooking', params: { lead: this.lead.id } })
    },
    onPlaceCall() {
      if (this.currentUser.roles.includes('SSC_REP')) {
        this.toastSuccess('Will attempt to start a call via the Nextiva app')
      } else {
        this.toastSuccess('Will attempt to start a call')
      }
      // will likely use vuex action (e.g., this.postCallNew(address)) that will return callId & externalTrackingId
    },
    // called as lead is updated
    setUpdatedLeadNote(k) {
      let m
      let prevVal
      let val
      switch (k) {
        case 'rep':
          prevVal = this.repList.find((e) => e.value === this.originalLead[k]).text
          val = this.repList.find((e) => e.value === this.lead[k]).text
          break
        case 'status':
          prevVal = this.leadStatusList.find((e) => e.value === this.originalLead[k]).text
          val = this.leadStatusList.find((e) => e.value === this.lead[k]).text
          break
        default:
          prevVal = this.originalLead[k]
          val = this.lead[k]
      }
      if (typeof this.originalLead[k] !== 'undefined') {
        m =
          `${this.$t(this.editableFields[k].label)} updated from ` +
          `${prevVal} to ${val}`
      } else {
        m = `${this.editableFields[k].label} added as ` + val
      }
      this.updatedLeadNotes.push(m)
    },
    doPostLeadNotes() {
      this.updatedLeadNotes.forEach((note) => {
        this.postLeadNote({
          user: this.currentUser['@id'],
          lead: this.leadId,
          note: note,
          timestamp: this.$moment().format(),
        })
      })
      this.updatedLeadNotes = []
    },
    async updateLead() {
      try {
        // loop through lead data, compare w/ originalLead data and update accordingly
        const save = { id: this.leadId }
        for (const k in this.editableFields) {
          if (this.lead[k] !== this.originalLead[k]) {
            save[k] = this.lead[k]
            this.setUpdatedLeadNote(k)
            // @XXX obvi needs to be confirmed first
            this.originalLead[k] = this.lead[k]
          }
        }
        await this.patchLead(save)
        this.doPostLeadNotes()
      } catch (e) {
        console.error('LeadDetails::updateLead() error', e)
      }
    },
  },
  watch: {
    lead: {
      deep: true,
      handler() {
        if (this.ini) {
          return
        }
        this.updateLead()
      },
    },
  },
  async mounted() {
    if (!this.leadStatusOptions.length) {
      await this.getLeadStatuses()
    }
    await this.getUsers({
      group: [5, 6],
      active: true,
      pagination: false,
      'order[profile.fname]': 'asc',
    })
    if (!this.lead.id) {
      // may be loaded via container
      await this.getLead(this.leadId)
    }
    this.setLead()
    this.ini = false
  },
}
</script>
