<template>
  <b-input-group>
    <b-form-textarea
      v-model="newNote"
      class="mb-2 mr-2"
      placeholder="Add a new note here (optional)."
    ></b-form-textarea>
    <b-input-group-append>
    <BaseFormButtons
      v-if="!noButtons"
      @form-button-submit="onSubmit"
      size="md"
      no-reset
      no-cancel
      block
    />
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "LeadNoteForm",
  props: {
    leadId: {
      type: [String, Number],
      default: null,
    },
    noButtons: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      newNote: null
    }
  },
  methods: {
    ...mapActions([
      'postLeadNote'
    ]),
    validate () {
    },
    async onSubmit () {
      try {
        if (!this.newNote) { return }
        this.validate()
        const note = {
          user: this.currentUser['@id'],
          lead: this.leadId,
          note: this.newNote,
          timestamp: this.$moment().format()
        }
        await this.postLeadNote(note)
        this.newNote = null
      } catch (e) {
        console.error(e)
      }
    }
  }
};
</script>
