export const leadEditableFields = {
    street1: { type: 'text', label: 'label.address' },
    street2: { type: 'text', label: 'label.address2' },
    city: { type: 'text', label: 'label.city' },
    country: { type: 'select', label: 'label.country' },
    zipCode: { type: 'text', label: 'label.zipCode' },
    email: { type: 'text', label: 'label.email' },
    fname: { type: 'text', label: 'label.firstName' },
    lname: { type: 'text', label: 'label.lastName' },
    clientFname: { type: 'text', label: 'label.firstName' },
    clientLname: { type: 'text', label: 'label.lastName' },
    franchiseCode: { type: 'text', label: 'label.franchiseCode' },
    managed: { type: 'text', label: 'lead.sscManaged' },
    phone: { type: 'text', label: 'label.phone' },
    refSource: { type: 'text', label: 'label.source' },
    rep: { type: 'select', label: 'label.rep' },
    state: { type: 'select', label: 'label.state' },
    status: { type: 'select', label: 'label.status' }
}
