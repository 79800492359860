<template>
  <b-navbar toggleable="lg" variant="light" class="mb-3" sticky>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{ name: 'LeadList' }">Leads</b-nav-item>
        <b-nav-item :to="{ name: 'LeadForm' }">New Lead</b-nav-item>
        <b-nav-item-dropdown text="Campaigns" right>
          <b-dropdown-item href="#">List</b-dropdown-item>
          <b-dropdown-item href="#">New Camnpaign</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item href="#">Status Messages</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'LeadsHeader'
}
</script>
