<template>
  <span class="inline-editor">
    <b-input-group
      v-if="editing"
      size="sm"
      :style="`max-width:${maxWidth}px`"
    >
      <input
        v-if="(isText || isNumber)"
        :class="inputClasses"
        class="form-control"
        :type="type" 
        :value="value" 
        :placeholder="placeholder"
        ref="inputEl"
        v-on:keyup.enter="handleEnter">

      <textarea 
        v-else-if="isTextArea"
        :class="inputClasses"
        class="form-control"
        :value="value"
        :placeholder="placeholder"
        ref="inputEl"
        :rows="rows"
        :cols="cols">
      </textarea>

      <select 
        v-else-if="isSelect"
        :class="inputClasses"
        class="form-control"
        :value="value"
        ref="inputEl"
        @change="handleChange">
        <option v-if="placeholder" disabled value>{{ placeholder }}</option>
        <option 
          :key="i"
          v-for="(o, i) in options" 
          :value="o.value">
          {{ o.text }}
        </option>
      </select>

      <BaseFormSelectFranchise
        v-else-if="isFranchise"
        :value="value"
        ref="inputEl"
      />

      <b-input-group-append>
        <b-button @click="handleSave" variant="success">Save</b-button>
        <b-button @click="handleBlur" variant="danger">Cancel</b-button>
      </b-input-group-append>
    </b-input-group>

    <span v-else :class="labelClass" :title="(!disabled ? 'Click to edit' : '')" @click="toggle()">
      <span v-html="label"></span>
      <slot name="selectCaret">
        <span v-if="(isSelect || isFranchise) && !disabled" class="text-muted" v-html="selectCaret"></span>
      </slot>
    </span>
  </span>
</template>

<script>
export default {
  name: 'InlineEditor',
  props: {
    value: {
      type: [Number, String],
      default: () => ('')
    },
    type: {
      type: String,
      default: () => ('text')
    },
    placeholder: {
      type: String,
      default: () => ('empty')
    },
    labelClasses: {
      type: String,
      default: null
    },
    inputClasses: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: () => (2)
    },
    cols: {
      type: Number,
      default: () => (20)
    },
    options: {
      type: Array,
      default: () => ([])
    },
    selectCaret: {
      type: String,
      default: () => ('&nbsp;&#9660')
    },
    maxWidth: {
      type: Number,
      default: () => (400)
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editing: false,
      selectedIndex: this.options.findIndex(o => o.value === this.value)
    }
  },
  computed: {
    isText() {
      return !['number','textarea','select','franchise'].includes(this.type)
    },
    isTel() {
      return this.type === 'tel'
    },
    isNumber() {
      return this.type === 'number'
    },
    isTextArea() {
      return this.type === 'textarea'
    },
    isSelect() {
      return this.type === 'select'
    },
    isFranchise() {
      return this.type === 'franchise'
    },
    label() {
      if (this.isNumber) return this.value === '' ? `<span class="label-empty">${this.placeholder}</span>` : this.value
      if (this.isText || this.isTextArea || this.isFranchise) return this.value ? this.value : `<span class="label-empty">${this.placeholder}</span>`
      // if (this.isFranchise) return  this.selectedIndex === -1 ? `<span class="label-empty">${this.placeholder}</span>` : this.options[this.selectedIndex].text
      // Select
      return this.selectedIndex === -1 ? `<span class="label-empty">${this.placeholder}</span>` : this.options[this.selectedIndex].text
    },
    labelClass () {
      let labelClass = this.labelClasses
      if (labelClass && !this.disabled) {
        labelClass = `${labelClass} cursor-pointer text-underline`
      }
      return labelClass
    },
    selectOptions () {
      if (this.isFranchise) {
        return this.$refs.inputEl.franchiseList
      } else {
        return this.options
      }
    }
  },
  methods: {
    toggle() {
      if (this.disabled) { return }
      this.editing = !this.editing
      
      if (this.editing) {
        this.$nextTick(() => {
          this.$refs.inputEl.focus()
        })
      }
    },
    handleSave() {
      this.toggle()
      this.emitValue()
    },
    handleEnter() {
      this.toggle()
      this.emitValue()
      // this.$refs.inputEl.blur()
    },
    handleBlur() {
      this.toggle()
      // this.emitValue()
      // this.$emit('blur', this.getValue())
    },
    handleInput() {
      this.emitValue()
    },
    handleChange(e) {
      this.selectedIndex = this.placeholder ? e.target.selectedIndex - 1 : e.target.selectedIndex
      // this.emitValue()
    },
    emitValue() {
      this.$emit('input', this.getValue())
    },
    getValue() {
      const val = this.isNumber ? +this.$refs.inputEl.value : this.$refs.inputEl.value
      return val
    }
  }
}
</script>

<style lang="scss">
.inline-editor {
  .text-underline {
    text-decoration: underline;
  }
  .label-empty {
    color: rgba(255,0,0,.5) !important;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba(255,0,0,.5);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: rgba(255,0,0,.5);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: rgba(255,0,0,.5);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: rgba(255,0,0,.5);
  }
}
</style>